import React, { useState, Fragment } from 'react';
import moment from 'moment';
import MyButton from '../../../../Commun/Button/Button';
import { Link } from 'react-router-dom';
import CallToAction from '../../../../Commun/Button/CallToAction/CallToAction';
import Spinner from "../../../../Commun/Spinner/Spinner";

const Contrat = ({ contrat, postGetTarifContract, tarif, loading_tarif }) => {
    const [apporteur1, setApporteur1] = useState(false);
    const [apporteur2, setApporteur2] = useState(false);

    const toggleDetailApp1 = () => setApporteur1(!apporteur1);
    const toggleDetailApp2 = () => setApporteur2(!apporteur2);

    const handleClickTarifContract = () => {
        postGetTarifContract()
    };

    const {
        hivernages, situation, portefeuille, apporteurs, date_paiement_cheque_cadeau, realisateur,
        banque, acompte, avantage, id, can_change_fractionnement, prelevement, vehicule
    } = contrat;

    const isVAE = vehicule.codeProduit === '7311_VAE'
    const isNVEI = vehicule.codeProduit === '7311_NVEI'

    return (
        <div className='bloc-info bloc-contrat'>
            <h2>Situation du contrat</h2>
            <div className='content'>
                <div className='row'>
                    <div className='col-5 left-content'>
                        {hivernages[0] && hivernages[0].statutCode === '1' && (
                            <p>Ma période hivernale :</p>
                        )}
                        {situation.dateFin && (
                            <Fragment>
                                <p>Résilié le :</p>
                                <p>Motif de résiliation :</p>
                                {situation.motifCode === '5' && moment().diff(moment(situation.dateFin), 'days') < 90 && (
                                    <p>Lien vers iQera :</p>
                                )}
                            </Fragment>
                        )}
                        <p>Echéance principale :</p>
                        <p>Date d'affaire nouvelle :</p>
                        <p>Fractionnement :</p>
                        {situation.jour_prel && <p>Date de prélèvement :</p>}
                        <p>Portefeuille :</p>
                        {apporteurs && (
                            <Fragment>
                                <p>Apporteur 1 :</p>
                                <div className={!apporteur1 ? 'hidden' : ''}>
                                    <p>Adresse :</p>
                                    {apporteurs.apporteur1.rue2 && <p>Complément d'adresse :</p>}
                                    <p>Code Postal - Ville :</p>
                                    <p>Téléphone :</p>
                                    <p>Email :</p>
                                </div>
                                <p>Taux de commission :</p>
                                {apporteurs.apporteur2.nom && <p>Apporteur 2 :</p>}
                                {(apporteurs.apporteur2.rue1 || apporteurs.apporteur2.phone) && (
                                    <div className={!apporteur2 ? 'hidden' : ''}>
                                        <p>Adresse :</p>
                                        {apporteurs.apporteur2.rue2 && <p>Complément d'adresse :</p>}
                                        <p>Code Postal - Ville :</p>
                                        <p>Téléphone :</p>
                                        <p>Email :</p>
                                    </div>
                                )}
                                {apporteurs.apporteur2.tauxCommission && <p>Taux de commission :</p>}
                                {date_paiement_cheque_cadeau && <p>Date de paiement du chèque cadeau :</p>}
                            </Fragment>
                        )}
                        {apporteurs.apporteur1.id !== '43397' && <p>Adhérent service PMQ :</p>}
                        {realisateur && <p>Réalisateur :</p>}
                        <p>IBAN :</p>
                        <p>RUM :</p>
                        {banque.titulaire && <p>Titulaire du compte :</p>}
                        <p>Acompte contrat :</p>
                        {(apporteurs.apporteur1.id === '43397' || apporteurs.apporteur1.premium) && <p>Assuré éligible fidélisation :</p>}
                        {avantage && <p>Avantage :</p>}
                        {(situation.statutCode === '1' && !isVAE && !isNVEI) && <p>Prime recalculée :</p>}
                    </div>
                    <div className='col-7 right-content'>
                        {hivernages[0] && hivernages[0].statutCode === '1' && (
                            <p>Activée jusqu’au {moment(hivernages[0].dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                        )}
                        {situation.dateFin && (
                            <Fragment>
                                <p>{moment(situation.dateFin).format('L')}</p>
                                <p>{situation.motif}</p>
                                {situation.motifCode === '5' && moment().diff(moment(situation.dateFin), 'days') < 90 && (
                                    <p><a href='https://extranet-defficlic.iqera.app/LoginOrpheeAction.do' target='_blank' rel='noopener noreferrer'>Accès iQera</a></p>
                                )}
                            </Fragment>
                        )}
                        <p>{situation.echeance || '-'}</p>
                        <p>{moment(situation.dateAffaireNouvelle).format('L')} à {situation.heureAffaireNouvelle}</p>
                        <p className={`${situation.fractionnement && situation.fractionnement !== 'Prélévement mensuel' ? 'error' : ''}`}>
                            {situation.fractionnement || '-'}
                            <CallToAction
                                url={{
                                    pathname: `/contrat/${id}/changement-fractionnement`,
                                    state: { fractionnement: situation.fractionnement, prelevement }
                                }}
                                action='edit-note'
                                toolText='Changer le fractionnement'
                                disabled={can_change_fractionnement === false}
                                disabledReason={(situation.statutCode !== '1' && situation.statutCode !== '2' ? 'La modification du fractionnement n\'est possible que sur les contrats en cours ou en établissement' : can_change_fractionnement === false && 'Vous ne pouvez changer le fractionnement que 2 mois avant la date d\'échéance maximum.')}
                            />
                        </p>
                        {situation.jour_prel && <p>Tous les {situation.jour_prel} du mois</p>}
                        <p>{portefeuille || '-'}</p>
                        {apporteurs && (
                            <Fragment>
                                <p className='inline'>
                                    <Link to={`/tiers/${apporteurs.apporteur1.id}`}>
                                        {apporteurs.apporteur1.raisonSociale}
                                        {apporteurs.apporteur1.premium && ' - Premium'}
                                    </Link>
                                    <MyButton
                                        className='btn btn-rounded'
                                        onClick={toggleDetailApp1}
                                    >
                                        {!apporteur1 ? '+' : '-'}
                                    </MyButton>
                                </p>
                                <div className={!apporteur1 ? 'hidden' : ''}>
                                    <p>{apporteurs.apporteur1.rue1}</p>
                                    {apporteurs.apporteur1.rue2 && <p>{apporteurs.apporteur1.rue2}</p>}
                                    <p>{apporteurs.apporteur1.codePostal} {apporteurs.apporteur1.ville}</p>
                                    <p>{apporteurs.apporteur1.phone || '-'}</p>
                                    <p>{apporteurs.apporteur1.email || '-'}</p>
                                </div>
                                <p>{apporteurs.apporteur1.tauxCommission || '-'}
                                    {apporteurs.apporteur1.fraisDossier > 0 && (
                                        <Fragment>
                                            <span> / Frais de dossier :</span> {apporteurs.apporteur1.fraisDossier}€
                                        </Fragment>
                                    )}
                                </p>
                                {apporteurs.apporteur2.nom && (
                                    <p className='inline'>
                                        <Link to={`/tiers/${apporteurs.apporteur2.id}`}>
                                            {apporteurs.apporteur2.nom}
                                            {apporteurs.apporteur2.premium && ' - Premium'}
                                        </Link>
                                        <MyButton
                                            className='btn btn-rounded'
                                            onClick={toggleDetailApp2}
                                        >
                                            {!apporteur2 ? '+' : '-'}
                                        </MyButton>
                                    </p>
                                )}
                                {apporteurs.apporteur2.nom && (
                                    <div className={!apporteur2 ? 'hidden' : ''}>
                                        <p>{apporteurs.apporteur2.rue1}</p>
                                        {apporteurs.apporteur2.rue2 && <p>{apporteurs.apporteur2.rue2}</p>}
                                        <p>{apporteurs.apporteur2.codePostal} {apporteurs.apporteur2.ville}</p>
                                        <p>{apporteurs.apporteur2.phone || '-'}</p>
                                        <p>{apporteurs.apporteur2.email || '-'}</p>
                                    </div>
                                )}
                                {apporteurs.apporteur2.tauxCommission && <p>{apporteurs.apporteur2.tauxCommission || '-'}</p>}
                            </Fragment>
                        )}
                        {date_paiement_cheque_cadeau && (
                            <p className='pb-3'>{moment(date_paiement_cheque_cadeau).format('L')}</p>
                        )}
                        {contrat.apporteurs.apporteur1.id !== '43397' && <p>{contrat.apporteurs.apporteur1.adherent_pmq ? 'OUI' : contrat.apporteurs.apporteur1.adherent_pmq === null ? 'N\'a pas répondu' : 'NON'}</p>}
                        {realisateur && <p>{realisateur}</p>}
                        <p>{banque.iban || '-'}</p>
                        <p>{banque.rum || '-'}</p>
                        {banque.titulaire && <p>{banque.titulaire}</p>}
                        <p>{acompte}€</p>
                        {(apporteurs.apporteur1.id === '43397' || apporteurs.apporteur1.premium) && (contrat.avenant_link_fid ? <p>OUI</p> : <p className='error'>NON</p>)}
                        {avantage && <p>{avantage}</p>}
                        {(situation.statutCode === '1' && !isVAE && !isNVEI) && (
                            <>
                                {tarif?.message && <p className={'text-danger'}>{tarif.message}</p>}
                                {tarif?.FractionnementChoisi === 'M' && (
                                    <>
                                        <p><b>{tarif?.PrimeAnnuelleFracMensuel}€/an</b> soit <b>{tarif?.PrimeMensuellePromo}€/mois</b></p>
                                        <p><b>{tarif?.MontantMensuelRegul.replace('-', '')}€/mois</b> de différence soit une {tarif?.RatioRegul.includes('-') ? 'diminution' : 'augmentation'} de <b>{tarif?.RatioRegul.replace('-', '')}%</b></p>
                                    </>
                                )}
                                {tarif?.FractionnementChoisi === 'A' && (
                                    <>
                                        <p><b>{tarif?.PrimeAnnuelleFracAnnuel}€/an</b></p>
                                        <p><b>{tarif?.MontantAnnuelRegul.replace('-', '')}€</b> de différence soit une {tarif?.RatioRegul.includes('-') ? 'diminution' : 'augmentation'} de <b>{tarif?.RatioRegul.replace('-', '')}%</b></p>
                                    </>
                                )}
                                {loading_tarif
                                    ? <Spinner />
                                    : <span className={'btn btn-cta'} onClick={handleClickTarifContract}>
                                        Recalculer la prime
                                    </span>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contrat;