import loader from "../../../../Commun/Spinner/Loader/Loader";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';

import _ from 'lodash';
import moment from 'moment';
import { POST_DEROGATIONS, postDerogations } from '../../../../../redux/actions/app/derogations/derogations.actions';
import {contractDefaultGetLink, createDerogationLink} from '../../../../../redux/selectors/links/links.selectors';
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors';
import { getUserRoles } from '../../../../../redux/selectors/user/user.selectors';
import Derogation from './Derogation';
import validate from './validate';
import {getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {ACCESSOIRES} from "../../../../../redux/actions/app/accessoires/accessoires.actions";

const mapStateToProps = (state, ownProps) => {
    const { idTiers, idContract, idAccident } = ownProps.match.params;
    const contract = getContractData(state);

    return {
        idTiers,
        idContract,
        idAccident,
        initialValues: {
            theme: '',
            date: moment().subtract(0, 'day'),
            description: '',
            motif: '',
            montant: '',
            informations: ''
        },
        formValues: formValueSelector('derogation')(state, 'theme', 'date', 'description', 'motif', 'montant', 'informations'),
        roles: getUserRoles(state),
        uriCreateDerogation: createDerogationLink(state),
        loading: getLoadingEntity(state, POST_DEROGATIONS) === true,
        uri: contractDefaultGetLink(state),
        contract: getContractData(state),
        error: getErrorEntity(state, POST_DEROGATIONS),
    };
};

const mapDispatchToProps = {
    postDerogations,
    getContract: (query) => getContract({query}),
    changeValue: (field, value) => change('derogation', field, value),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postDerogations, getContract } = dispatchProps;
    const { idTiers, idContract, idAccident = null, uriCreateDerogation, uri } = stateProps;

    let query = _.replace(uriCreateDerogation, '{tiers_id}', idTiers);
    query = _.replace(query, '{contract_id}', idContract);
    query = _.replace(query, '{accident_id}', idAccident);

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmit: (form) => postDerogations({ query, form }),
        load: () => getContract(_.replace(uri, '{id}', idContract))
    };
};

const DerogationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'derogation',
        validate,
    }),
)(Derogation);

export default DerogationContainer;
