import React, {Component, Fragment} from 'react'
import moment from "moment";
import CallToAction from "../Button/CallToAction/CallToAction";
import {redirectInNewWindow} from "../../../utils/function";
import _ from "lodash";
import {openInWindow} from "../../../constants/constants";

export default class QuittanceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledBtnPay: false
        }
    }

    handleClick = () => {
        this.props.postPayReceipt({
            contract: this.props.quittance.id_contrat,
            receipt: this.props.quittance.id,
        })
        this.setState({
            disabledBtnPay: true
        })
    }

    handleClickEditDocument = (quittance) => {
        this.props.postLogActionEditDocument(quittance)
    }

    render() {
        const {quittance, publishUrl, contractId, tiersId, roles, situation} = this.props
        let diff = moment(moment().format("YYYY-MM-DD")).diff(situation.dateAffaireNouvelle, 'days');

        return (
            <Fragment>
                <tr>
                    <td>du {moment(quittance.dateDebut).format('L')} <br/> au {moment(quittance.dateFin).format('L')}
                    </td>
                    <td>{quittance.numPiece}</td>
                    <td>{quittance.type}</td>
                    <td>{quittance.infos}</td>
                    <td>{quittance.montant}&nbsp;€</td>
                    <td>{quittance.fraisGestion}&nbsp;€</td>
                    <td>{quittance.solde}&nbsp;€</td>
                    <td>
                        {!_.includes(roles, 'gestionnaire televente') ?
                            !_.includes(roles, 'gestionnaire externe') ?
                                <CallToAction
                                    action={'edit-document'}
                                    toolText={'Editer un document'}
                                    url={`${publishUrl}&contrat=${contractId}&tiers=${tiersId}&quittance=${quittance.id_belair}`}
                                    publish
                                    link_externe
                                    onClick={() => {
                                        this.handleClickEditDocument(quittance.id_belair)
                                    }}
                                />
                                : ((situation.statutCode === '2' && diff > 60) || situation.statutCode === 'I') ? '' :
                                    <CallToAction
                                        action={'edit-document'}
                                        toolText={'Editer un document'}
                                        url={`${publishUrl}&contrat=${contractId}&tiers=${tiersId}&quittance=${quittance.id_belair}`}
                                        publish
                                        link_externe
                                        onClick={() => {
                                            this.handleClickEditDocument(quittance.id_belair)
                                        }}
                                    />
                            : ''}
                    </td>
                    <td>
                        {quittance.pay_link && <CallToAction
                            link_externe
                            action={'paiement'}
                            toolText={'Faire un paiement'}
                            url={`${quittance.pay_link}${quittance.pay_link.indexOf('?') > 0 ? '&' : '?'}url_return=${window.location.href}`}
                        />}
                        {!quittance.pay_link_mail_reason[0] && <CallToAction
                            action={'paiement-mail'}
                            toolText={'Envoyer le lien de paiement au client'}
                            onClick={() => {
                                this.handleClick()
                            }}
                            disabled={this.state.disabledBtnPay}
                            url={'#'}
                        />}
                    </td>
                    <td>
                        {!_.includes(roles, 'gestionnaire televente') ? <button className={'btn btn-more'}
                                                                                onClick={(e) => redirectInNewWindow({
                                                                                    e,
                                                                                    url: `/quittances/${quittance.id_belair}`,
                                                                                    name: openInWindow.quittance
                                                                                })}/> : ''}
                    </td>
                </tr>
            </Fragment>
        )
    }
}
