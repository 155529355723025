import React, {Component} from 'react'
import KofaxContainer from './KofaxDetail/KofaxDetailContainer'
import RedirectToTaskForm from './RedirectToTaskForm'
import {isVerifiedByKofax} from "../../../../../utils/function";
import {Link} from "react-router-dom";
import Spinner from "../../../../Commun/Spinner/Spinner";

class TaskDetail extends Component {
    componentDidMount() {
        this.props.logAction(this.props.taskDetail.paniere)
    }

    render() {
        const {
            taskDetail,
            terminate,
            goToForm,
            postValid,
            postValidSinistre,
            postReassignTask,
            postValidateContract,
            postCloseTask,
            loading,
            loadingTerminate,
            loadingDoc,
            reassign,
            belair_id
        } = this.props

        return (
            <div className={'task-detail-container'}>
                {!terminate && !reassign
                    ? isVerifiedByKofax(taskDetail.files_url)
                        ? goToForm
                            ? <RedirectToTaskForm
                                task={taskDetail}
                                postValid={postValid}
                                postReassignTask={postReassignTask}
                                postCloseTask={postCloseTask}
                                loadingTerminate={loadingTerminate}
                                loading={loadingDoc}
                                belair_id={belair_id}
                            />
                            : <KofaxContainer task={taskDetail}/>
                        : taskDetail && taskDetail.paniere === 'ATT_ALC' && taskDetail.sinistre 
                            ? <RedirectToTaskForm
                                task={taskDetail}
                                postValid={postValidSinistre}
                                postReassignTask={postReassignTask}
                                postCloseTask={postCloseTask}
                                loadingTerminate={loadingTerminate}
                                loading={loadingDoc}
                                belair_id={belair_id}
                            />
                            : <RedirectToTaskForm
                                task={taskDetail}
                                postValid={postValid}
                                postReassignTask={postReassignTask}
                                postCloseTask={postCloseTask}
                                loadingTerminate={loadingTerminate}
                                loading={loadingDoc}
                                belair_id={belair_id}
                            />
                    : <div className={'container'}>
                        <div className={'row margin-top text-center'}>
                            <div className={'col'}>
                                <h2 className={'title-primary title-success'}>Votre tâche a bien été
                                    clôturée&nbsp;!</h2>
                            </div>
                        </div>
                        {!reassign &&
                            <div className={'row margin-top'}>
                                {taskDetail.contract && (taskDetail.contract.situation.statutCode === '2' && !taskDetail.paniere.includes('RES')) &&
                                    <div className={'col-6'}>
                                        <div className={'bloc-info bloc-contrat'}>
                                            <h2>Passer le contrat en cours</h2>
                                            <p>
                                                Toutes les pièces bloquantes sur ce contrat ont été reçues ?
                                                Vous allez passer le contrat "En cours" pour le valider.Cela va
                                                déclencher
                                                l'envoi de la carte verte au client et la création du tableau de
                                                prélèvement
                                            </p>
                                            <button
                                                className={'btn btn-primary'}
                                                onClick={() => postValidateContract()}
                                                disabled={loading}
                                            >
                                                {loading && <Spinner absolute/>}
                                                J'ai reçu toutes les pièces bloquantes
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div
                                    className={`${taskDetail.contract && taskDetail.contract.situation.statutCode === '2' ? 'col-6' : 'col-12'}`}>
                                    <div className={'bloc-info bloc-transfere'}>
                                        <h2>Transférer la tache</h2>
                                        <p>L’un des documents à traiter était destiné à un autre service ?<br/>
                                            Vous pouvez créer une nouvelle tâche avec les documents pour finaliser la
                                            demande du client</p>
                                        <Link to={{
                                            pathname: `/tache/${taskDetail.id}/reaffecter`,
                                            state: {task: taskDetail, keep_old: 1}
                                        }} className={'btn btn-primary'}>
                                            Transférer la tâche
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default TaskDetail
