import React, {Component, Fragment} from 'react'
import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import RadioFieldRedux from "../../../../Commun/Input/Radio/RadioFieldRedux";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";

class ReassignTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
        };
    }

    handleChange = (e) => {
        this.setState({type: e.target.value})
    }

    render() {
        const {loading, handleSubmit, is_reassign, keep_old, task, notTask} = this.props
        const {type} = this.state

        return (
            <div className={'task-detail-container container'}>
                {!is_reassign ?
                    <Fragment>
                        {notTask && <>
                            <div className={'row return-content'}>
                                <div className={'col'}>
                                    <button onClick={() => this.props.history.go(-1)}
                                            className={'btn btn-return'}>Retour
                                    </button>
                                </div>
                            </div>
                        </>}
                        <div className={'row header-row'}>
                            {notTask && <>
                                <div className={'col-12'}>
                                    {type === 'sinistre' ?
                                        <div className={'alert alert-warning'}>
                                            Attention, la réaffectation sur un numéro de sinistre ne change pas la
                                            panière du popup. Si vous souhaitez changer la panière, merci de passer par
                                            la réaffectation à un autre service.
                                        </div>
                                        :
                                        keep_old ?
                                            <div className={'alert alert-warning'}>
                                                Vous allez réaffecter l’ensemble de la tâche à une autre panière.
                                                Sélectionnez la panière associée à cette tâche
                                            </div>
                                            : <div className={'alert alert-warning'}>
                                                Vous vous apprêtez à créer une nouvelle tâche à un autre service.
                                                Sélectionnez la panière associée à cette tâche
                                            </div>

                                    }
                                </div>
                            </>}
                            <div className={'col-12'}>
                                <form className={'form-content'} onSubmit={handleSubmit}>
                                    <Field
                                        name={'type'}
                                        component={RadioFieldRedux}
                                        label={`${keep_old ? 'Réaffectez la tâche à' : 'Créer une nouvelle tâche à'} `}
                                        data={[{value: "contrat", label: "Un contrat"}, {
                                            value: "sinistre",
                                            label: "Un sinistre"
                                        }, {value: "paniere", label: "Une panière"}]}
                                        onChange={this.handleChange}
                                    />
                                    {type === 'paniere' &&
                                        <Field
                                            name='paniere'
                                            component={SelectFieldRedux}
                                            label={'Panière associée à la tâche'}
                                        >
                                            <option value={''}>Sélectionner la panière</option>
                                            <optgroup label="Service Prospect"></optgroup>
                                            <option value={'AN'}>AN</option>
                                            <option value={'AG_AN'}>AG_AN</option>
                                            <option value={'AG_ESP_AN'}>AG_ESP_AN</option>

                                            <optgroup label="Service Vie de Contrat"></optgroup>
                                            <option value={'AV'}>AV</option>
                                            {task.paniere === 'AV' && <option value={'DDE_AV'}>DDE_AV</option>}
                                            <option value={'AG_AV'}>AG_AVENANT</option>
                                            <option value={'AG_ESP_VDC'}>AG_ESP_VDC</option>
                                            <option value={'AG_FID'}>AG_FIDELISATION</option>
                                            <option value={'AG_IMPAYES'}>AG_IMPAYES</option>
                                            <option value={'AG_RES'}>AG_RESILIATION</option>
                                            <option value={'INFO_COTI'}>INFO_COTI</option>
                                            <option value={'RES'}>Traitement d'une résiliation</option>
                                            <option value={'RECLA'}>RECLA</option>
                                            <option value={'RIB'}>RIB</option>


                                            <optgroup label="Service Indemnisation"></optgroup>
                                            <option value={'INDEM'}>INDEM</option>
                                            <option value={'AG_ESP_INDEM'}>AG_ESP_INDEM</option>
                                            <option value={'AG_INDEM'}>AG_INDEM</option>
                                            <option value={'DEC'}>Déclaration de sinistre</option>
                                            <option value={'DEC_RDV'}>DEC_RDV</option>
                                            <option value={'FACTURE'}>FACTURE</option>
                                            <option value={'MAIL_CIE'}>MAIL_CIE</option>
                                            <option value={'RECLA_SIN'}>RECLA_SIN</option>
                                            <option value={'RGT'}>RGT</option>
                                        </Field>
                                    }
                                    {type === 'contrat' &&
                                        <Field
                                            name={'contrat'}
                                            component={TextFieldRedux}
                                            label={'Numéro de police du contrat'}
                                        />
                                    }
                                    {type === 'sinistre' &&
                                        <Field
                                            name={'sinistre'}
                                            component={TextFieldRedux}
                                            label={'Numéro de sinistre'}
                                        />
                                    }
                                    <MyButton
                                        variant='raised'
                                        color='secondary'
                                        size='large'
                                        className={'btn btn-primary'}
                                        disabled={loading}
                                    >
                                        {loading && <Spinner absolute/>}
                                        Réaffecter
                                    </MyButton>
                                </form>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <div className={'text-center'}>
                        <h2 className={'title-primary title-success'}>Votre tâche a bien été transférée&nbsp;!</h2>
                    </div>

                }
            </div>
        )
    }
}

export default ReassignTask
