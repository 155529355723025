import {createSelector} from 'reselect'
import _ from 'lodash'

const contractSelector = state => state.contract.data
const tarifSelector = state => state.tarif_contract.data

export const getTarifContractData = createSelector(
    tarifSelector,
    data => data || {}
)
export const getContractData = createSelector(
    contractSelector,
    data => data || {}
)

/* ID */
export const getContractId = createSelector(
    getContractData,
    data => data.id
)

/* Adhesion Principale */
export const getAdhesionPrincipale = createSelector(
    getContractData,
    data => data.adhesionPrincipale
)

/* Contract */
export const getContractPolice = createSelector(
    getContractData,
    data => data.numeroPolice
)

/* Souscripteur */
const getContractSouscripteur = createSelector(
    getContractData,
    data => data.souscripteur || {}
)

export const getContractIdSouscripteur = createSelector(
    getContractSouscripteur,
    souscripteur => souscripteur.id
)

export const getContractNomSouscripteur = createSelector(
    getContractSouscripteur,
    souscripteur => souscripteur.nom
)

export const getContractEmailSouscripteur = createSelector(
    getContractSouscripteur,
    souscripteur => souscripteur.email
)

export const getContractPhoneSouscripteur = createSelector(
    getContractSouscripteur,
    souscripteur => souscripteur.phone
)

export const getContractTitreSouscripteur = createSelector(
    getContractSouscripteur,
    souscripteur => souscripteur.titre
)

/* Situation */
const getContractSituation = createSelector(
    getContractData,
    data => data.situation
)

export const getContractStatut = createSelector(
    getContractSituation,
    situation => situation.statut
)

export const getContractResilMotif = createSelector(
    getContractSituation,
    situation => situation.motif
)

export const getContractStatutCode = createSelector(
    getContractSituation,
    situation => situation.statutCode
)

export const getDateEcheanceContract = createSelector(
    getContractSituation,
    situation => situation?.dateEcheance
)
export const getContractEcheance = createSelector(
    getContractSituation,
    situation => situation.echeance
)

export const getContractSituationDate = createSelector(
    getContractSituation,
    situation => situation.date
)

export const getContractResilDate = createSelector(
    getContractSituation,
    situation => situation.dateFin
)

export const getContractAfnDate = createSelector(
    getContractSituation,
    situation => situation.dateAffaireNouvelle
)

/* Vehicule */
const getContractVehicule = createSelector(
    getContractData,
    data => data.vehicule || {}
)

export const getContractCRM = createSelector(
    getContractVehicule,
    vehicule => vehicule.crm
)

export const getContractImmatriculation = createSelector(
    getContractVehicule,
    vehicule => vehicule.immatriculation
)

export const getContractCodeProduit = createSelector(
    getContractVehicule,
    vehicule => vehicule.codeProduit
)

export const getIsWarkamContract = createSelector(
    contractSelector,
    contract => contract?.compagnie === "WAKAM"
)

export const getContractMarque = createSelector(
    getContractVehicule,
    vehicule => vehicule.marque
)

export const getContractModele = createSelector(
    getContractVehicule,
    vehicule => vehicule.modele
)

export const getContractCylindree = createSelector(
    getContractVehicule,
    vehicule => vehicule.cylindree
)

export const getContractMarqueModele = createSelector(
    getContractMarque,
    getContractModele,
    (marque, modele) => `${marque} ${modele}`
)

/* Garanties */
export const getContractGaranties = createSelector(
    getContractData,
    data => _.filter(data.garanties, ['type', 'G'])
)

export const getContractGarantiesDA = createSelector(
    getContractGaranties,
    data => _.filter(data, ['code', 'DA'])
)

export const getContractAllGaranties = createSelector(
    getContractData,
    data => data.garanties
)

/* Options */
export const getContractOptions = createSelector(
    getContractData,
    data => _.filter(data.garanties, ['type', 'O'])
)

export const getContractValeurAccessoires = createSelector(
    getContractVehicule,
    vehicule => vehicule.valeurAccessoires
)

export const getContractGarantiesOptions = createSelector(
    getContractData,
    data => data.garanties
)

/* Releve Information */
export const getContractRiLink = createSelector(
    getContractData,
    data => data.ri_link
)

export const getContractRiLinkReason = createSelector(
    getContractData,
    data => data.ri_link_reason[0]
)


/* Link Claim Accident Reason*/
export const getContratAccidentClaimLink = createSelector(
    getContractData,
    data => data.accident_claim_link_reason[0]
)

export const getContratAvenantLinkReason = createSelector(
    getContractData,
    data => data.avenant_link_reason[0]
)

/* Link Claim Accident Mobilite */
export const getAccidentMobClaimLink = createSelector(
    getContractData,
    data => data.accident_mob_claim_link
)
/* Reason Mobilite */
export const getReasonMobClaimLink = createSelector(
    getContractData,
    data => data.accident_mob_claim_link_reason[0]
)

/* Upload Document */
export const getContractUploadLink = createSelector(
    getContractData,
    data => data.upload_link
)

/* Receipts Link */
export const getExportLinkData = createSelector(
    getContractData,
    data => data.receipts_link
)

/* Lien de la signature */
export const getSignatureLinkData = createSelector(
    getContractData,
    data => data.signature_link
)

/* Information Banque */
const getBanqueContract = createSelector(
    getContractData,
    data => data.banque
)

export const getContractBic = createSelector(
    getBanqueContract,
    banque => banque.bic
)

export const getContractDomiciliation = createSelector(
    getBanqueContract,
    banque => banque.domiciliation
)

export const getContractTitulaire = createSelector(
    getBanqueContract,
    banque => banque.titulaire
)

export const getContractIban = createSelector(
    getBanqueContract,
    banque => banque.iban
)

/* Avenant */
export const getAvenantNonTarifantLinkReason = createSelector(
    getContractData,
    data => data.avenant_non_tarifant_link_reason[0]
)

/* Prelevement */
export const getContractPrelevement = createSelector(
    getContractData,
    data => data.prelevement
)

export const getIsFleetContract = createSelector(
    getContractData,
    contract => contract.flotte
)

export const getIsACLContract = createSelector(
    getContractCodeProduit,
    codeProduit => ['7311_ACL', '229127_AC', '229127_CC', '229127_PR', '229127_FR'].includes(codeProduit)
)

export const getHivernagesContract = createSelector(
    getContractData,
    contract => contract.hivernages || []
)
export const getDernierHivernageContract = createSelector(
    getHivernagesContract,
    hivernages => hivernages[0] || null
)
export const getIsEligibleHivernageContract = createSelector(
    getContractData,
    contract => contract.is_eligible_hivernage || false
)
export const getCanActivateHivernageContract = createSelector(
    getContractData,
    contract => contract.can_activate_hivernage || false
)