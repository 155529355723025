import React from 'react'
import {Link} from "react-router-dom";
import _ from "lodash";

const Requetes = props => {
    const {submitExtractionCVComptabilite, roles, submitBordereaux} = props;

    return (
        <div className={'requetes-container suivi-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary title-task'}>Requêtes à executer.</h1>
                    <div className={'row poles'}>
                        {_.includes(roles, 'gestionnaire compta') &&
                            <>
                                <div className={'col-3'}>
                                    <div className={'item text-center'}>
                                        <h3 className={'d-block'} style={{color: '#1e3947'}}>Reversement compagnies</h3>
                                        <Link
                                            to={{pathname: '/comptabilite/reversement-compagnies'}}
                                            className={'btn btn-cta btn-success btn-small'}
                                        >
                                            Extraction
                                        </Link>
                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className={'item text-center'}>
                                        <h3 className={'d-block'} style={{color: '#1e3947'}}>Carte verte</h3>
                                        <button
                                            onClick={() => {
                                                if (window.confirm('Voulez-vous vraiment lancer l\'extraction des cartes vertes ?')) submitExtractionCVComptabilite()
                                            }}
                                            className={'btn btn-cta btn-success btn-small'}
                                        >
                                            Extraction
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                        <div className={'col-3'}>
                            <div className={'item text-center'}>
                                <h3 className={'d-block'} style={{color: '#1e3947'}}>Bordereaux de commissions</h3>
                                <button
                                    onClick={() => {
                                        if (window.confirm('Voulez-vous vraiment lancer la génération des bordereaux de commissions ?')) {
                                            submitBordereaux();
                                        }
                                    }}
                                    className={'btn btn-cta btn-success btn-small'}
                                >
                                    Générer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Requetes
