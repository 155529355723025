import {Fragment} from 'react';
import moment from 'moment';
import CallToAction from '../../../../Commun/Button/CallToAction/CallToAction';
import _ from 'lodash';
import { redirectInNewWindow } from '../../../../../utils/function';
import { listMailJet, openInWindow } from '../../../../../constants/constants';

const Buttons = (props) => {
    const {
        isACLContract,
        contract,
        reasonAccident,
        publishUrl,
        reasonAvenant,
        prelevement,
        noteUrl,
        permissions,
        roles,
        reasonAvenantTarif,
        reclamations,
        is_eligible_hivernage,
        postLogActionEditDocument,
        postLogActionFormAccident,
        postLogActionFormRib,
        postLogActionAvenant,
        postLogActionFormChangeAdress, isWakam
    } = props;
    let reasonDisabledRib = prelevement ? reasonAvenant : "Ce contrat n'est pas en prélèvement";
    let threemonthago = moment().subtract(3, 'months');
    let dateEffetDerPiece = moment(contract.situation.date);

    const handleClickEditDocument = () => {
        postLogActionEditDocument();
    };

    const handleClickFormSinistre = (e, contract) => {
        postLogActionFormAccident();

        redirectInNewWindow({ e, url: `/contrat/${contract.id}/declaration-de-sinistre`, name: openInWindow.formulaire });
    };

    const handleClickFormRib = () => {
        postLogActionFormRib();
    };

    const handleClickAvenant = () => {
        postLogActionAvenant();
    };

    const handleDisabled = (e, contract, permissions) => {
        if (!(contract.situation.statutCode === 'A' || contract.situation.statutCode === '2' || contract.situation.statutCode === '9' || !_.includes(permissions, 'create typed amendment - change address') || contract.vehicule.libelleProduit === 'AIMCO')) {
            postLogActionFormChangeAdress();
            redirectInNewWindow({ e, url: `/contrat/${contract.id}/modifie-adresse`, name: openInWindow.formulaire });
        }
    };

    return (
        <div className={'buttons'}>
            {(!contract.flotte || isWakam) && (
                <CallToAction
                    action={'edit-document'}
                    toolText={'Editer un document'}
                    url={`${publishUrl}&contrat=${contract.id}&tiers=${contract.souscripteur.id}`}
                    publish
                    link_externe
                    onClick={handleClickEditDocument}
                />
            )}

            {_.includes(permissions, 'edit minor contract informations') &&
                contract.id_devis !== '' &&
                (contract.situation.statutCode === '1' || contract.situation.statutCode === '2') &&
                threemonthago.isBefore(dateEffetDerPiece) && (
                <CallToAction
                    action={'edit-contract'}
                    toolText={'Modification contrat'}
                    onClick={(e) => redirectInNewWindow({ e, url: `/contrat/${contract.id}/avenant-simple`, name: openInWindow.avenant })}
                />
            )}

            {
                (!contract.flotte || isWakam) && (
                    <>
                        <CallToAction
                            action={'change_address'}
                            toolText={"Modifier l'adresse"}
                            url={false}
                            onClick={(e) => {
                                handleDisabled(e, contract, permissions);
                            }}
                            disabled={
                                contract.situation.statutCode === 'A' ||
                                contract.situation.statutCode === '2' ||
                                contract.situation.statutCode === '9' ||
                                !_.includes(permissions, 'create typed amendment - change address') ||
                                contract.vehicule.libelleProduit === 'AIMCO' ||
                                (contract.situation.fractionnement && contract.situation.fractionnement.indexOf('semestriel') !== -1) ||
                                contract.situation.statutCode !== '1'
                            }
                            disabledReason={
                                (contract.vehicule.libelleProduit === 'AIMCO' && "Le changement d'adresse n'est pas possible sur les contrats AIMCO") ||
                                (!_.includes(permissions, 'create typed amendment - change address') && "Vous n'avez pas la permission") ||
                                ((contract.situation.statutCode === 'A' || contract.situation.statutCode === '2' || contract.situation.statutCode === '9') &&
                                    "Le statut du contrat ne permet pas le changement d'adresse") ||
                                ((contract.situation.fractionnement && contract.situation.fractionnement.indexOf('semestriel') !== -1) &&
                                    "Le contrat est en fractionnement semestriel, merci de passer par le formulaire d'avenant.")
                            }
                        />
                        <CallToAction
                            url={`/contrat/${contract.id}/modifie-rib`}
                            action={'validate_rib'}
                            toolText={'Modifier le RIB'}
                            disabled={!prelevement || _.includes(roles, 'gestionnaire externe')}
                            disabledReason={_.includes(roles, 'gestionnaire externe') ? "Vous n'avez pas la permission pour effectuer cette action" : reasonDisabledRib}
                            onClick={handleClickFormRib}
                        />
                    </>
                )
            }

            {
                isWakam &&
                <>
                    <CallToAction
                        link_externe
                        url={contract.avenant_link}
                        disabled={contract.situation.statutCode === '2'}
                        disabledReason={contract.situation.statutCode === '2' && "Le contrat est en établissement"}
                        action={'avenant'}
                        toolText={'Modifier le contrat'}
                    />

                    <CallToAction
                        action={'sortie_vehicule'}
                        toolText={'Sortir un véhicule'}
                        onClick={(e) => redirectInNewWindow({ e, url: `/contrat/${contract.id}/sortie-vehicule`, name: openInWindow.addActivities })}
                        disabled={contract.situation.statutCode === '2'}
                        disabledReason={contract.situation.statutCode === '2' && "Le contrat est en établissement"}
                    />

                    <CallToAction
                        action={'validate_rib'}
                        toolText={'Modifier le RIB'}
                        disabled={!prelevement || _.includes(roles, 'gestionnaire externe')}
                        disabledReason={_.includes(roles, 'gestionnaire externe') ? "Vous n'avez pas la permission pour effectuer cette action" : reasonDisabledRib}
                        onClick={(e) => {
                            handleClickFormRib()
                            redirectInNewWindow({
                                e,
                                url: `/contrat/${contract.id}/modifie-rib`,
                                name: openInWindow.rib
                            });
                        }}
                    />

                    {
                        !isWakam && <CallToAction
                            link_externe
                            url={contract.avenant_link}
                            disabled={contract.situation.statutCode === '2'}
                            disabledReason={contract.situation.statutCode === '2' && "Le contrat est en établissement"}
                            action={'ajout_vehicule'}
                            toolText={'Ajouter un véhicule'}
                        />
                    }
                </>
            }

            {
                !isWakam && <CallToAction
                    action={'accident'}
                    toolText={'Déclarer un sinistre'}
                    url={false}
                    disabled={isACLContract || !contract.accident_claim_link || contract.situation.statutCode === 'A' || !_.includes(permissions, 'create accident')}
                    disabledReason={isACLContract ? 'Déclaration impossible sur un contrat ACL' : contract.situation.statutCode !== 'A' ? reasonAccident : 'Déclaration désactivée si contrat résilié / impayé'}
                    onClick={(e) => {
                        handleClickFormSinistre(e, contract);
                    }}
                />
            }

            {!contract.flotte && (
                <Fragment>
                    {_.includes(['2', '8'], contract.situation.statutCode) ? (
                        <CallToAction
                            /*link_externe*/
                            action={'avenant'}
                            disabled={!contract.avenant_link || !_.includes(permissions, 'create amendment')}
                            toolText={'Mise à jour de déclaration'}
                            disabledReason={!_.includes(permissions, 'create amendment') ? "Vous n'avez pas la permission pour effectuer cette action" : reasonAvenantTarif}
                            /*url={contract.avenant_link}*/
                            onClick={() => {
                                if (window.confirm("Le contrat n'est pas en cours, voulez-vous poursuivre ?")) window.open(contract.avenant_link, '_blank', 'noopener,noreferrer');
                            }}
                        />
                    ) : (
                        <CallToAction
                            link_externe
                            action={'avenant'}
                            disabled={!contract.avenant_link || !_.includes(permissions, 'create amendment')}
                            toolText={contract.situation.statutCode === '5' ? 'Remise en vigueur' : 'Faire un avenant'}
                            disabledReason={!_.includes(permissions, 'create amendment') ? "Vous n'avez pas la permission pour effectuer cette action" : reasonAvenantTarif}
                            url={contract.avenant_link}
                            onClick={handleClickAvenant}
                        />
                    )}
                    <CallToAction
                        disabled={!contract.avenant_link && !_.includes(permissions, 'create pay deposit')}
                        disabledReason={!_.includes(permissions, 'create pay deposit') && "Vous n'avez pas la permission pour effectuer cette action"}
                        action={'paiement'}
                        toolText={'Faire un paiement'}
                        onClick={(e) => redirectInNewWindow({ e, url: `/contrat/${contract.id}/paiement`, name: openInWindow.paiement })}
                    />
                </Fragment>
            )}
            <CallToAction
                action={'add-chrono'}
                toolText={'Ajouter un chrono / agenda'}
                onClick={(e) => redirectInNewWindow({ e, url: `/contrat/${contract.id}/ajout-suivi`, name: openInWindow.addActivities })}
            />
            {!_.includes(roles, 'gestionnaire externe') && (!contract.flotte || isWakam) && (
                <CallToAction
                    url={{
                        pathname: `/contrat/${contract.id}/note`,
                        state: {
                            url: noteUrl,
                            title: `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id}`,
                            subtitle: `Contrat n°${contract.numeroPolice} - ${contract.title}`,
                        }
                    }}
                    action={'add-note'}
                    toolText={'Ajouter une note'}
                    disabled={contract.total_notes >= 2}
                    disabledReason={contract.total_notes >= 2 && "Le contrat ne peut pas avoir plus de 2 notes d'informations"}
                />
            )}
            {(_.includes(roles, 'gestionnaire externe') && (contract.situation.statutCode === '1' || contract.situation.statutCode === '2')) && (
                <CallToAction
                    url={{
                        pathname: `/contrat/${contract.id}/note`,
                        state: {
                            url: noteUrl,
                            title: `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id}`,
                            subtitle: `Contrat n°${contract.numeroPolice} - ${contract.title}`,
                        },
                    }}
                    action={'add-note'}
                    toolText={'Ajouter une note'}
                    disabled={contract.total_notes >= 2}
                    disabledReason={contract.total_notes >= 2 && "Le contrat ne peut pas avoir plus de 2 notes d'informations"}
                />
            )}
            <CallToAction
                action={'email'}
                toolText={'Envoyer un email'}
                onClick={(e) => redirectInNewWindow({ e, url: `/envoyer-email/libre/${listMailJet.libre}/${contract.souscripteur.id}/${contract.id}`, name: `${openInWindow.emailContract}${contract.id}` })}
            />
            {(!contract.flotte || isWakam) && (
                <CallToAction
                    action={'reclamation'}
                    toolText={'Ouvrir une réclamation'}
                    onClick={(e) => redirectInNewWindow({ e, url: `/reclamation/${contract.souscripteur.id}/${contract.id}`, name: openInWindow.formulaire })}
                    data-class={reclamations.length > 0 ? 'hasReclamation' : null}
                />
            )}
            <CallToAction
                action={'derogation'}
                toolText={'Créer une dérogation / fidélisation'}
                onClick={(e) => redirectInNewWindow({ e, url: `/derogation/${contract.souscripteur.id}/${contract.id}`, name: `${openInWindow.derogation}${contract.id}` })}
            />
            {is_eligible_hivernage && (
                <CallToAction
                    action={'hivernage'}
                    toolText={'Gérer Ma période hivernale'}
                    onClick={(e) => redirectInNewWindow({ e, url: `/contrat/${contract.id}/hivernage`, name: `${openInWindow.hivernage}${contract.id}` })}
                />
            )}
        </div>
    );
};

export default Buttons;
