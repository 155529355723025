import _ from 'lodash';
import { useEffect, useState } from 'react';
import { listMailJet, openInWindow } from '../../../../../constants/constants';
import { redirectInNewWindow } from '../../../../../utils/function';
import AddDocumentContainer from '../../Forms/AddDocument/AddDocumentContainer';
import DocumentItemContainer from './DocumentItem/DocumentItemContainer';
import DocumentItemLight from './DocumentItem/DocumentItemLight';
import './documents.scss';

const Documents = ({ documents = [], contractId, tiersId, risqueNum, type, logAction, idSinistre, roles }) => {
    const [selectedAdhesion, setSelectedAdhesion] = useState(null);
    const [addDoc, setAddDoc] = useState(false);

    // Recherche du numéro de pièce le plus élevé lors du chargement initial
    useEffect(() => {
        if (documents.length > 0) {
            const maxPiece = Math.max(...documents.map((doc) => parseInt(doc.nb_piece)));
            const adhesionWithMaxPiece = documents.find((doc) => parseInt(doc.nb_piece) === maxPiece)?.adhesion;
            setSelectedAdhesion(adhesionWithMaxPiece);
        }
    }, [documents]);

    const handleAdhesionChange = (e) => {
        setSelectedAdhesion(e.target.value);
    };

    const handleAddDoc = () => {
        setAddDoc(!addDoc);
    };

    // Filtrer les documents en fonction de l'adhesion sélectionnée
    const filteredDocuments = selectedAdhesion
        ? documents.filter((document) => document.adhesion === selectedAdhesion)
        : documents;
    

    const optionsAdhesion = documents
        .map((document) => {
            return {
                adhesion: document.adhesion,
                nb_piece: document.nb_piece,
                motif_piece: document.motif_piece,
            };
        })
        .filter((value, index, self) => {
            return self.findIndex((t) => t.adhesion === value.adhesion) === index;
        });

    return (
        <div className={'bloc-info bloc-documents'}>
            <h2>Base documentaire</h2>
            {!_.includes(roles, 'gestionnaire televente') && (
                <div className={'call-to-action-bloc-info'}>
                    <span
                        className={'btn btn-cta btn-email float-right'}
                        onClick={(e) =>
                            redirectInNewWindow({
                                e,
                                url: `/envoyer-email/pmq/${
                                    type !== 'sinistre' ? listMailJet.pmq : listMailJet.pmqAccident
                                }/${tiersId}/${contractId}${idSinistre ? `/${idSinistre}` : ''}`,
                                name: openInWindow.email,
                            })
                        }
                    >
                        Envoyer par email
                    </span>
                </div>
            )}

            <div className="form-group select-adhesion">
                <label htmlFor="adhesion">Sélectionner l'adhésion :</label>
                <select
                    className="form-control"
                    onChange={handleAdhesionChange}
                    value={selectedAdhesion || ''}
                >
                    <option value="">Toutes les adhésions</option>
                    {optionsAdhesion.map((adhesion, index) => {
                        return (
                            <option key={adhesion.adhesion} value={adhesion.adhesion}>
                                {`Pièce N° ${adhesion.nb_piece} - Motif ${adhesion.motif_piece}`}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className={'content row'}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Commentaire</th>
                            {type === 'sinistre' && <th scope="col">Date de création</th>}
                            <th scope="col" className={'text-center'}>
                                Recu
                            </th>
                            <th scope="col" className={'text-center'}>
                                Traité
                            </th>
                            <th scope="col">Détails</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Affichage des documents filtrés */}
                        {filteredDocuments.map((document, index) =>
                            !_.includes(roles, 'gestionnaire televente') ? (
                                <DocumentItemContainer
                                    roles={roles}
                                    logAction={logAction}
                                    document={document}
                                    key={`document-${document.code}-${document.adhesion}`}
                                    form={`document-${document.code}-${document.adhesion}`}
                                    type={type}
                                />
                            ) : (
                                <DocumentItemLight
                                    logAction={logAction}
                                    document={document}
                                    key={`document-${document.code}-${document.adhesion}`}
                                    form={`document-${document.code}-${document.adhesion}`}
                                    type={type}
                                />
                            ),
                        )}
                    </tbody>
                </table>
                {!_.includes(roles, 'gestionnaire televente') && (
                    <div className={'col-12'}>
                        {addDoc ? (
                            <AddDocumentContainer
                                risqueNum={risqueNum}
                                contractId={contractId}
                                tiersId={tiersId}
                                type={type}
                                sinistre={idSinistre}
                            />
                        ) : (
                            <button className={'no-background'} onClick={handleAddDoc}>
                                + Ajouter un document attendu
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Documents;
