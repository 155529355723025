import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
    CONTRACT, GET_TARIF_CONTRACT,
    getContract,
    getTarifContract, TARIF_CONTRACT,
} from '../../../../redux/actions/app/contracts/contracts.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../redux/selectors/ui/ui.selectors'
import Contract from './Contract'
import {contractDefaultGetLink, tarifContractGetLink} from '../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getContractData, getTarifContractData} from '../../../../redux/selectors/contract/contract.selectors'
import _ from "lodash";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {

    return {
        idContract: ownProps.match.params.id,
        contract: getContractData(state),
        uri: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        roles: getUserRoles(state),
        uri_tarif: tarifContractGetLink(state),
        tarif: getTarifContractData(state),
        loading_tarif: getLoadingEntity(state, TARIF_CONTRACT) === true,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract, getTarifContract
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract, getTarifContract} = dispatchProps
    const {uri, idContract, uri_tarif} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract({query: _.replace(uri, '{id}', idContract), id: idContract}),
        postGetTarifContract: () => getTarifContract({query: _.replace(uri_tarif, '{id}', idContract)}),
    }
}

const ContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Contract)

export default ContractContainer
